<template>
  <lab-task><lab-separating-mixture /></lab-task>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabTask from './labs/LabTask';
import LabSeparatingMixture from '@/tasks/components/simulations/LabSeparatingMixture.vue';

export default defineComponent({
  name: 'SeparatingMixtureLab',
  components: {LabSeparatingMixture, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
