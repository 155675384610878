




import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent} from '@vue/composition-api';
export default defineComponent({
  name: 'LabSeparatingMixture',
  components: {LabSim},
  inheritAttrs: false,
});
